exports.components = {
  "component---src-views-about-page-index-tsx": () => import("./../../../src/views/AboutPage/index.tsx" /* webpackChunkName: "component---src-views-about-page-index-tsx" */),
  "component---src-views-book-list-page-index-tsx": () => import("./../../../src/views/BookListPage/index.tsx" /* webpackChunkName: "component---src-views-book-list-page-index-tsx" */),
  "component---src-views-book-page-index-tsx": () => import("./../../../src/views/BookPage/index.tsx" /* webpackChunkName: "component---src-views-book-page-index-tsx" */),
  "component---src-views-home-page-index-tsx": () => import("./../../../src/views/HomePage/index.tsx" /* webpackChunkName: "component---src-views-home-page-index-tsx" */),
  "component---src-views-not-found-page-index-tsx": () => import("./../../../src/views/NotFoundPage/index.tsx" /* webpackChunkName: "component---src-views-not-found-page-index-tsx" */),
  "component---src-views-video-list-page-index-tsx": () => import("./../../../src/views/VideoListPage/index.tsx" /* webpackChunkName: "component---src-views-video-list-page-index-tsx" */),
  "component---src-views-video-page-index-tsx": () => import("./../../../src/views/VideoPage/index.tsx" /* webpackChunkName: "component---src-views-video-page-index-tsx" */)
}

